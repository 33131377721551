.list-remove-action {
  color: var(--primary-red);
  font-weight: 600;
  cursor: pointer;
}

.remove-loading {
  color: #ff4d4f80;
}

.list-add-action {
  justify-content: flex-end;
  width: 100%;
  padding-right: 0.5rem !important;
}

.invite-form-item-name {
  width: 80%;
  margin: 0 !important;
}

.invite-form-item-submit {
  width: 20%;
  margin: 0 !important;
}
