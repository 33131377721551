.list-remove-action {
  @apply text-xs;
  color: var(--primary-red);
  font-weight: 600;
}

.verification-badge {
  @apply ml-2 flex gap-1 items-center text-xs;
}
.verified {
  color: var(--primary-green);
}

.not-verified {
  color: var(--primary-orange);
}

.remove-loading {
  color: #ff4d4f80;
}

.align-end {
  @apply text-right !important;
}

.list-add-action {
  @apply flex gap-1;
}

.invite-modal-form-item {
  margin-bottom: 0;
}
