.holder {
  @apply pt-2 pb-3 mx-auto my-0 px-4;
}

.flex-container {
  @apply flex items-baseline justify-between;
}

.actions-container {
  @apply flex gap-2;
}

.reports-filter-container {
  @apply p-4 border-b-2;

  border-color: var(--primary-border);
}

.search-input {
  width: 100%;
}
