.holder {
  @apply flex items-center justify-center w-full;

  height: calc(100vh - 100px);
}

.content {
  @apply mt-8;
}

.empty-main {
  @apply flex items-center justify-center flex-col;
}
