.holder {
  @apply pt-2 pb-3 mx-auto my-0 px-4;
}

.flex-container {
  @apply flex items-baseline justify-between;
}

.link {
  @apply flex items-center !important;
}
a.link {
  @apply pt-1.5 !important;
}

.link-icon {
  @apply ml-1 !important;
}

.actions-container {
  @apply flex gap-2;
}

.reports-filter-container {
  @apply p-4 border-b-2;

  border-color: var(--primary-border);
}

.tag {
  @apply normal-case !important;
}

.previous-page-link-container {
  padding: 0.75rem 0.825rem;
  text-align: left;
  border: 1px solid #f1f1f1;
  border-radius: 0.5rem;
  margin-bottom: 0.5rem;
}

.previous-page-link {
  cursor: pointer;
  font-size: 1rem;
}
.previous-page-link:hover {
  color: rgba(0, 0, 0, 0.5);
}

.search-input {
  width: 100%;
}

.download-complete {
  display: flex;
  gap: 1rem;
}

.download-progress {
  display: flex;
  align-items: flex-start;
  gap: 2rem;
}
.download-progress > article {
  margin-top: -2px;
}
