.main {
  overflow: hidden;
  width: 100vw;
  height: 100vh;
}

.leftImageHolder {
  width: 100%;
  height: 100vh;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.rightContentHolder {
  padding-left: 8%;
  padding-right: 8%;
}

.logoHolder {
  display: flex;
  align-items: center;
  margin-top: 40px;
}
.logoHolder img {
  margin-right: 15px;
}
.logoHolder .logoText {
  margin-bottom: 0 !important;
}

.loginContent {
  width: 100%;
  height: 80vh;
  display: flex;
  align-items: center;
}

.loginText {
  font-size: 1rem !important;
}

.oAuthLoginButton {
  width: 100%;
  height: 44px;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 900;
  color: var(--primary-theme) !important;
  border-color: var(--primary-theme) !important;
  transition-duration: 0.3s;
  margin-top: 30px;
}

.oAuthLoginButton img {
  width: 20px;
  height: 20px;
  margin-right: 10px;
}

.oAuthLoginButton:hover,
.oAuthLoginButton:active,
.oAuthLoginButton:focus {
  transform: scale(1.01);
  background: var(--primary-theme) !important;
  color: #fff !important;
  transition-duration: 0.3s;
}
