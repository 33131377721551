.sider {
  @apply border-r-2;
  background: var(--primary-white) !important;
}
.sider > div ul {
  background: var(--primary-white) !important;
}
.sider > div ul li:hover {
  background: none !important;
}

.collapsed .styled-menu {
  @apply pt-0 pb-2.5 px-5 !important;
}

.styled-menu {
  @apply h-full border-r-2 overflow-auto !important;
}
.styled-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: none !important;
}

.sidebar-head {
  @apply p-4 pb-1 flex items-center gap-4 !important;
}

.styled-sub-menu {
  @apply py-0 pr-1 pl-4 mt-5 text-slate-400 text-xs !important;
}
.styled-sub-menu > .ant-menu-submenu-title {
  @apply p-0 h-5 uppercase font-semibold outline-0 leading-5 !important;
}

.menu-group-header {
  @apply py-0 px-4 mt-5 !important;
}
.menu-group-header > div {
  @apply p-0 text-xs uppercase mb-1 text-slate-400 font-semibold !important;
}
.menu-group-header > ul > li {
  @apply p-0 !important;
}
.menu-group-header > ul > li::after {
  @apply content-none border-r-0 !important;
}

.menu-item {
  @apply h-fit !important;
  @apply p-0 bg-transparent text-black text-sm leading-6 !important;
}
.menu-item::after {
  @apply content-none border-r-0 !important;
}
.menu-item a {
  @apply font-medium bg-transparent !important;
}
.menu-item.ant-menu-item-selected {
  @apply bg-transparent !important;
}
.menu-item.ant-menu-item-selected a {
  @apply text-blue-400 !important;
}
.menu-item.ant-menu-item-active a {
  @apply text-blue-400 !important;
}

.custom-menu-item-group > .ant-menu-item-group-title {
  @apply p-0 mb-1 font-medium !important;
}
.custom-menu-item-group a {
  @apply text-black !important;
}
.custom-menu-item-group .ant-menu-item {
  @apply py-0 px-3 !important;
}
.custom-menu-item-group .ant-menu-item::after {
  @apply content-none border-r-0 !important;
}

.link-button {
  @apply pl-0 font-semibold text-xs flex items-center gap-2 !important;
}
