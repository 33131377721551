.navbar {
  @apply w-full h-16 border-b-2 p-2 flex items-center justify-end gap-4;

  background-color: var(--primary-white);
  border-color: var(--primary-border);
}

.user-details-container {
  @apply flex items-center gap-2 mx-2 cursor-pointer;
}
.user-details-container > div:nth-child(2) {
  @apply mt-1.5;
}

.dropdown-info {
  @apply flex items-center gap-2 text-sm font-semibold;
}

.user-designation {
  color: var(--secondary-grey);
}
