@import "tailwindcss/base";
@import "tailwindcss/utilities";

:root {
  /* Fonts */
  --primary-font: "Lato", sans-serif;
  --font-button-size: 12px;
  /* Colors */
  --primary-theme: rgba(24, 144, 255, 1);
  --primary-theme-light: rgba(24, 144, 255, 0.1);
  --primary-black: rgba(0, 0, 0, 0.8);
  --primary-white: rgba(255, 255, 255, 1);
  --primary-red: rgba(255, 77, 79, 1);
  --primary-red-light: rgba(255, 77, 79, 0.1);
  --primary-green: rgba(16, 187, 103, 1);
  --primary-green-light: rgba(16, 187, 103, 0.1);
  --primary-orange: rgba(255, 159, 28, 1);
  --primary-orange-processing: #fa8c16;
  --primary-orange-light: rgba(255, 159, 28, 0.1);
  --primary-border: #f1f1f1;
  --primary-grey: #f8f8f8;

  --secondary-black: rgba(0, 0, 0, 0.5);
  --secondary-border: #eeeeee;
  --secondary-grey: #666666;
}

#root,
body,
html {
  height: 100%;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.ant-layout {
  background-color: #fff;
}

.ant-layout-header {
  position: fixed;
  z-index: 20;
}

.ant-dropdown-trigger {
  position: relative;
  z-index: 50;
}

.ant-layout-sider {
  @apply fixed overflow-y-hidden !important;
  z-index: 10;
  height: 100vh;
  background-color: transparent;
}
.ant-layout-sider > div ul {
  background-color: #f0f2f5 !important;
}
.ant-layout-sider > div ul li:not(:last-child) {
  margin-bottom: 0px !important;
}
.ant-layout-sider > div ul li:hover {
  background: rgba(229, 231, 235, 1) !important;
}

.ant-layout-sider-trigger {
  background: #f0f2f5 !important;
  color: rgba(0, 0, 0, 0.85) !important;
}

.ant-menu-submenu.ant-menu-submenu-popup .ant-menu.ant-menu-sub {
  padding: 20px !important;
  border-radius: 12px !important;
}

.ant-menu.ant-menu-inline-collapsed
  > .ant-menu-submenu
  > .ant-menu-submenu-title
  .anticon {
  line-height: 20px !important;
}

.ant-layout-content {
  @apply bg-white !important;
}

.ant-layout-sider-trigger:hover {
  background: rgba(229, 231, 235, 1) !important;
}

.ant-menu-inline-collapsed {
  width: 50px !important;
}

.ant-modal-wrap {
  @apply flex items-center;
}

.select-option-systemType > .ant-select-item-option-content {
  @apply flex items-center justify-between;
}

.variant-info-check-group {
  @apply flex items-center justify-around;
}

.variant-info-check-group > label {
  @apply flex items-center;
}

.variant-info-check-group > label > * {
  margin-right: 5px;
}

.csv-reader-input {
  background-color: #ebede9;
  text-align: center;
  border-radius: 4px;
  font-weight: 500;
  cursor: pointer;
}

.csv-reader-input > .dropzone {
  @apply flex items-center justify-between outline-none p-4;
}

.csv-reader-input > .dropzone > p {
  @apply outline-none;
}

/* Global CSS */

.standard-padding {
  @apply py-3 px-6;
}

.standard-flex {
  @apply flex items-center;
}

.page-main-container {
  @apply pt-4 pb-3 mx-auto my-0 px-4;
}

.breadcrumb-holder {
  @apply mb-4;
}

.theme-button {
  @apply text-white bg-[#1890ff] text-sm !important;
}

.ant-input-feedback-icon {
  display: flex !important;
}

.ant-select-selection-item-remove {
  display: flex !important;
  align-items: center;
}

.ant-btn {
  @apply flex gap-1 !important;
}

.ant-modal-confirm-btns {
  @apply w-full flex justify-end;
}

.invite-modal-wrap-classname {
  @apply items-baseline;
}

.invite-modal-wrap-classname > div [class~="ant-modal-content"] {
  @apply h-full;
  @apply rounded-br-none rounded-bl-none !important;
}

.invite-modal-wrap-classname > div [class~="ant-modal-body"] {
  @apply overflow-y-scroll;
  height: calc(100% - 55px);
}

.invite-modal-wrap-classname > div [class~="ant-modal-footer"] {
  @apply block !important;
  background-color: --primary-grey;
}
