.divider {
  @apply mr-4 !important;
  height: 60px;
}

.custom-status-select [class~="ant-select-selector"] {
  @apply bg-[#eeeeee] rounded-xl !important;
}

.custom-status-select label {
  @apply text-stone-500 text-xs !important;
}

.custom-status-select [class~="ant-form-item-explain-error"] {
  @apply text-xs mt-1 !important;
}
