.holder {
  @apply pt-2 pb-3 mx-auto my-0 px-4;
}

.file-uploading {
  @apply absolute left-0 top-0 w-full h-full flex justify-center z-50 -mt-2;

  background: rgba(255, 255, 255, 0.7);
}

.loader > div {
  @apply -left-4;
}

.previous-page-link-container {
  padding: 0.75rem 0.825rem;
  text-align: left;
  border: 1px solid #f1f1f1;
  border-radius: 0.5rem;
  margin-bottom: 0.5rem;
}

.previous-page-link {
  cursor: pointer;
  font-size: 1rem;
}
.previous-page-link:hover {
  color: rgba(0, 0, 0, 0.5);
}
